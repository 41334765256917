<template>
  <main id="main" class="main body">
    <div class="pagetitle">
      <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
        <ol class="breadcrumb m-3">
          <li class="breadcrumb-item">Bilans</li>
          <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/bilan" style="text-decoration:none">Bilan Périodique</router-link></li>
          <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/detail" style="text-decoration:none">détail</router-link></li>
        </ol>
      </nav>
      <h3>Détails</h3>
      <hr class="border-bottom" />
      <p>Détail d'un communautaire de tous réseaux<i class="bi bi-emoji-sunglasses-fill text-warning "></i></p>
      <div class="row">
        <div>
          <!-- Affiche le loader si loading est vrai -->
          <div v-if="loading" class="text-center my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Chargement en cours...</span>
            </div>
          </div>
          <!-- Affiche le tableau une fois que les données sont chargées -->
          <table v-if="!loading" class="table table-responsive">
            <thead>
              <tr>
                <th class="table-active" scope="col">Date Note</th>
                <th class="table-active" scope="col">Date Action</th>
                <th class="table-active" scope="col">Notes</th>
                <th class="table-active" scope="col">Reseaux</th>
                <th class="table-active" scope="col">Responsables</th>
                <th class="table-active" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-for="(liste, index) in backendData" :key="index">
                <td>{{ formatDate(liste.date_note) }}</td>
                <td>{{ formatDate(liste.created_at) }}</td>
                <td>{{ liste.note }}</td>
                <td>{{ liste.reseaux }}</td>
                <td>{{ liste.responsables }}</td>
                <td>
                  <button class="m-1 btn "  data-bs-toggle="modal" data-bs-target="#edit" style="color: white ; background-color:blue" >
                    Edit
                  </button>
                </td>
                <td>
                  <button @click="deleteNote(liste.id)" class="btn " style="color: white ; background-color:red">Sup</button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Modal pour la modification -->
                        <div class="modal fade" id="edit" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title text-center" id="editCommunauteLabel">Modifier un Communautaire</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                        <!-- Ajoutez ici le contenu du formulaire pour la modification -->
                                        <form >
                                 <div class="form-group">
                         
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input border-black" v-model="like" type="checkbox" id="inlineCheckbox1" value="2" />
                                        <label class="form-check-label text-primary" for="inlineCheckbox1"><strong>Like</strong></label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input border-black" v-model="commentaire" type="checkbox" id="inlineCheckbox2" value="3" />
                                        <label class="form-check-label text-info-emphasis" for="inlineCheckbox2"><strong>Commentaire</strong></label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input border-black" v-model="partage" type="checkbox" id="inlineCheckbox3" value="5" />
                                        <label for="inlineCheckbox3" class="form-label text-danger-emphasis"><strong>Partage</strong></label>
                                    </div>
                                    

                                    <button v-if="loader" class="btn" style="background-color: #019d0b; color: white" type="button">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        chargement...
                                    </button>
                                    <!-- <router-link to="/dashboard/ajout_note" style="text-decoration: none; color: white">Valider</router-link> -->
                                    <button v-else type="submit" class="btn d-flex justify-content-center align-items-center mb-4" style="background-color: blue">
                                        <span class="text-white">Valider</span>
                                    </button>
                    </div>
         
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axiosInstance from "../api/axios";
import { useRoute } from "vue-router";

export default {
  data() {
    return {
      backendData: [], // Tableau pour stocker les données du backend
      communautaireId: null,
      loading: false, // Variable pour le loader
    };
  },
  methods: {
    // Fonction pour formater la date au format "yyyy-mm-dd"
    formatDate(date) {
      const formattedDate = new Date(date);
      const year = formattedDate.getFullYear();
      const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
      const day = String(formattedDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    fetchData() {
      this.loading = true; // Activer le loader au début du chargement

      const route = useRoute();
      this.communautaireId = route.params.communautaireId;

      axiosInstance.get(`api/communautaires/${this.communautaireId}/notes`)
        .then(response => {
          this.backendData = response.data.liste;
          this.loading = false; // Désactiver le loader une fois les données chargées
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données depuis le backend :', error);
          this.loading = false; // Désactiver le loader en cas d'erreur
        });
    },

      deleteNote(noteId) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette note ?")) {
        axiosInstance.delete(`api/note_delete/${noteId}`)
          .then(response => {
            // Mise à jour de la liste après la suppression
            this.backendData = this.backendData.filter(note => note.id !== noteId);
            console.log("Note supprimée avec succès :", response.data);
          })
          .catch(error => {
            console.error("Erreur lors de la suppression de la note :", error);
          });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
  input {
    border-radius: 10px;
    width: 70%;
  }
</style>
