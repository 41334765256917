<template>
    <main id="main" class="main body">
        <h3 class="fs-4 text-primary">{{ nomReseaux }}</h3>
         <div class="pagetitle titre">
  <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
    <ol class="breadcrumb m-3">
      
      <li class="breadcrumb-item active" aria-current="page">Notes</li>
      <li class="breadcrumb-item active" aria-current="page"><a href="">Ajout Note </a> </li>
    </ol>
  </nav>
            <h1>Ajout note</h1>
            <hr class="border-bottom" />
        </div>
        
        <section class="section">
            <div class="card">
                <div class="card-body table-responsive">
                    <div v-if="loading" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Chargement en cours...</span>
                        </div>
                    </div>

                    <div>
                        <form action="submit" @submit.prevent="noter()">
                            <div class="row">
                                <div class="col-3">
                                    <label for="" class="mb-2"><strong>Date note</strong></label>
                                    <input type="date" v-model="formData.date_note" class="p-1" />
                                    <p v-if="warningDate" class="text-warning">Veuillez entrez une date inférieur ou égale a la date d'aujourd'hui</p>
                                </div>
                                <div class="col-3">
                                    <label for="reseaux" class="form-label"><strong>Choisir un réseau</strong></label>
                                    <select name="reseaux" id="reseaux" v-model="formData.reseaux" class="form-select">
                                        <option value="" disabled>Sélectionnez un réseau</option>
                                        <option v-for="reseau in reseaux" :key="reseau.id" :value="reseau.id">{{ reseau.libelle }}</option>
                                    </select>
                                    <p v-if="warningReseau" class="text-warning">Veuillez choisir un réseau</p>
                                </div>
                            </div>
                            <div class="row mt-2">
            <div class="col-6">
                <label for="searchCommunautaires" class="form-label"><strong>Rechercher un communautaire</strong></label>
                <input type="text" v-model="searchTerm" id="searchCommunautaires" class="form-control" placeholder="Entrez le nom du communautaire" />
            </div>
        </div>
                             <div>
                                <table class="table table-striped mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">N°</th>
                                            <th scope="col">Communautaires</th>
                                            <th scope="col" class="text-primary"><i class="bi bi-hand-thumbs-up-fill"></i></th>
                                            <th scope="col" class="text-primary"><i class="bi bi-chat-dots-fill"></i></th>
                                            <th scope="col" class="text-primary"><i class="bi bi-cursor-fill"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(note, index) in filteredCommunautaires" :key="index">
                                            <th scope="row">{{ index + 1 }}</th>
                                            <td>{{ note.identite }}</td>
                                            <td>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input border-black" v-model="note.like" type="checkbox" id="inlineCheckbox1" value="2" />
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input border-black" v-model="note.commentaire" type="checkbox" id="inlineCheckbox2" value="3" />
                                                </div>
                                            </td>

                                            <td>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input border-black" v-model="note.partage" type="checkbox" id="inlineCheckbox3" value="5" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button type="submit" class="btn d-flex justify-content-end mb-4" style="background-color: blue">
                                <span class="text-white">Valider</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    data() {
        return {
           searchTerm: "",
            reseaux: [],
            dataTables: [],
            tableHeaders: [],
            communautaires: [],
            displayedCommunautaires: [],
            loading: false,
            warningReseau: false,
            warningDate: false,
           formData: {
    reseaux: "",
    date_note: "",
    like: false,
    commentaire: false,
    partage: false,
},
            nomReseaux: "",
        };
    },
    methods: {
       noter() {
    if (this.formData.reseaux == 1) {
        this.endpoint = "/api/note_facebook";
    } else if (this.formData.reseaux == 2) {
        this.endpoint = "/api/note_instagram";
    } else if (this.formData.reseaux == 3) {
        this.endpoint = "/api/note_twitter";
    } else if (this.formData.reseaux == 4) {
        this.endpoint = "/api/note_tikTok";
    } else if (this.formData.reseaux == 5) {
        this.endpoint = "/api/note_linkedin";
    }

    if (this.formData.like == true) {
        this.formData.like = 2;
    } else {
        this.formData.like = 0;
    }

    if (this.formData.commentaire == true) {
        this.formData.commentaire = 3;
    } else {
        this.formData.commentaire = 0;
    }

    if (this.formData.partage == true) {
        this.formData.partage = 5;
    } else {
        this.formData.partage = 0;
    }

            axiosInstance
                .post(this.endpoint, this.formData)
                .then((response) => {
                    let statut = response.data.status;

                    if (statut == "422") {
                        const backendErrors = response.data.msg;
                        for (let el in backendErrors) {
                            this.showToastInfo(`${backendErrors[el][0]}`);
                        }
                        this.isLoading = false;
                    } else {
                        this.showToastSuccess(`${response.data.msg}`);
                        this.isLoading = false;

                        setTimeout(() => {
                            this.$router.push("/dashboard/ajout_note");
                        }, 3000);
                    }
                    this.resetFormFields();
                })
                .catch((error) => {
                    console.log(error);
                });
            console.log(this.formData);
        },

        Reseaux() {
            axiosInstance
                .get("api/reseaux")
                .then((response) => {
                    this.reseaux = response.data.liste;
                    const reseauxNoms = this.reseaux.map((item) => item.libelle);
                    this.tableHeaders = [...reseauxNoms];
                    if (response.data && response.data.success) {
                        // Récupérez les clés de l'objet JSON pour les utiliser comme en-têtes de tableau
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des réseaux sociaux : ", error);
                });
        },
        Communautaires() {
            axiosInstance
                .get("api/communautaires")
                .then((response) => {
                    this.communautaires = response.data.liste;
                    this.displayedCommunautaires = [...this.communautaires];
                    this.dataTables = [];
                    for (let i = 0; i < this.communautaires.length; i++) {
                        let note = {
                            identite: this.communautaires[i].identite,
                            reseaux: "",
                            communautaires: this.communautaires[i].id,
                            like: 0,
                            partage: 0,
                            commentaire: 0,
                            date_note: "",
                        };

                        this.dataTables.push(note);
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des communautaires : ", error);
                });
        },
        estDateFuture(dateStr) {
    var dateObj = new Date(dateStr);
    var dateActuelle = new Date();

    if (dateObj > dateActuelle) {
        return true;
    } else {
        return false;
    }
        },
        showToastWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
       noter() {
              if (!this.estDateFuture(this.formData.date_note) && this.formData.reseaux !== "") {
                this.warningReseau = false;
                this.warningDate = false;

                for (let i = 0; i < this.dataTables.length; i++) {
                    Reflect.deleteProperty(this.dataTables[i], "identite");

                    Object.defineProperty(this.dataTables[i], "reseaux", {
                        value: this.formData.reseaux,
                    });

                    Object.defineProperty(this.dataTables[i], "date_note", {
                        value: this.formData.date_note,
                    });

                    this.dataTables[i].like = this.formData.like ? 2 : 0;
                    this.dataTables[i].commentaire = this.formData.commentaire ? 3 : 0;
                    this.dataTables[i].partage = this.formData.partage ? 5 : 0;
                }

                const formTable = {
                    donnees_formulaire: this.dataTables,
                };

                axiosInstance
                    .post("api/ajout_note", formTable)
                    .then((response) => {
                        this.Communautaires();
                        if (response.data.status === 200) {
                            this.showToastSuccess(response.data.msg);
                            console.log("reussi",response.data)
                        }else{
                            this.showToastWarning(response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                if (this.estDateFuture(this.formData.date_note)) {
                    this.warningDate = true;
                    setTimeout(() => {
                        this.warningDate = false;
                    }, 1500);
                }

                if (this.formData.reseaux == "") {
                    this.warningReseau = true;
                    setTimeout(() => {
                        this.warningReseau = false;
                    }, 1500);
                }
            }
        },
    },
     mounted() {
        this.loading = true;
        this.Reseaux();
        this.Communautaires();
        this.loading = false;
    },
     computed: {
        // Utilisez une propriété calculée pour filtrer les communautaires en fonction du terme de recherche
        filteredCommunautaires() {
            return this.displayedCommunautaires.filter(communautaire =>
                communautaire.identite.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        },
    },
};
</script>

<style scoped>
body {
    background-color: #f6f9ff;
    font-size: 17px;
}

a {
    color: #034101;
    text-decoration: none;
}

a:hover {
    color: #010113;
    text-decoration: none;
}

#main {
    margin-top: 5px;
    padding: 20px 30px;
}

@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}

.pagetitle {
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle h1 {
    font-size: 24px;
    font-weight: 600;
    color: #044cd3dc;
}

.pagetitle a {
    color: #044cd3dc;
}

.card {
    border: none;
}

.card-title {
    font-weight: bold;
}

.card-title h5 {
    font-size: 20px;
    color: #000918;
}

.card-body {
    padding: 0 20px 20px 20px;
}

.obligatoire {
    color: #034101;
}
</style>
