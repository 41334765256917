<template>
    <div class="d-flex flex-column h-100">
        <div id="wrapper-page" class="d-flex" style="overflow-x: hidden">
            <nav class="flex-shrink-0 vh-100 position-fixed sidebar-vertical w-100" style="background-color: blue">
                <div class="p-2 text-center">
                    <a href="#"><img src="../assets/communaute3.png" height="80" alt="Communauté" /></a>
                    <hr class="my-3" />
                </div>
                <ul class="list-unstyled sidebar-ul ps-0 overflow-auto">
                    <div class="py-2 px-3 text-white" style="background-color:#044cd3dc; ">Vous êtes connecté en tant que <strong> Responsables {{ info.type }}</strong></div>
                    
                    <li class="sidebar-heading ms-3 mt-3 text-secondary text-uppercase">Communautaires</li>

                    <li class="li-sidebar">
                        <router-link to="/dashboard/ajout_communaute" class="d-block"><i class="bi bi-pencil"></i><span>Ajout Communautaire</span></router-link>
                    </li>
                      <li class="li-sidebar">
                        <router-link to="/dashboard/liste_communaute" class="d-block"><i class="bi bi-card-list"></i><span>Liste Communautaire</span></router-link>
                    </li>

                    <li class="sidebar-heading ms-3 mt-3 text-secondary text-uppercase">Notes</li>

                    <li class="li-sidebar">
                        <router-link to="/dashboard/ajout_note" class="d-block"><i class="bi bi-funnel"></i><span>Ajout Note</span></router-link>
                    </li>
                    
                    <li>
                        <div class="collapse" id="orders-collapse">
                            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ps-3">
                                <li><a href="#">Modifier mes infos personnelles</a></li>
                                <li><a href="#">Modifier mot de passe</a></li>
                                <li><a href="#">Changer photo de profil</a></li>
                            </ul>
                        </div>
                    </li>

                    

                     <li class="sidebar-heading ms-3 mt-2 text-secondary text-uppercase">Bilans</li>
                    <li class="li-sidebar">
                        <router-link to="/dashboard/bilan" class="d-block"><i class="bi bi-question-octagon"></i><span>Bilan périodique</span></router-link>
                    </li>
                      <li class="li-sidebar">
                        <router-link to="/dashboard/bilan_responsable" class="d-block"><i class="bi bi-question-octagon"></i><span>Bilan Responsable</span></router-link>
                    </li>
                     <li class="li-sidebar">
                        <router-link to="/dashboard/bilan_communautaire" class="d-block"><i class="bi bi-question-octagon"></i><span>Bilan Communautaire</span></router-link>
                    </li>

                </ul>
            </nav>

            <div id="wrapper-content">
                <header>
                    <nav class="navbar navbar-expand-lg bg-white shadow-sm">
                        <div class="container-fluid">
                            <a id="link-toggle" @click="toggle()" class="fs-3 text-black"><i class="bi bi-list"></i></a>
                            <div class="ms-lg-3 ms-auto d-flex align-items-center">
                                <ul class="navbar-nav topnav">
                                  
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle text-black" href="#" id="ndm" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-person-circle fs-5"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-end border-0 position-absolute shadow" aria-labelledby="ndm" style="max-height: 400px; padding: 1rem; overflow: auto">
                                            <span class="fw-bold text-secondary"> Pseudo </span>
                                            <li><hr class="dropdown-divider" /></li>
                                            <li>
                                                <a class="dropdown-item" href="#"><i class="bi bi-person-lines-fill"></i> Tableau de bord</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="#"><i class="bi bi-person-lines-fill"></i> Autres liens</a>
                                            </li>
                                            <!-- <router-link to="/dashboard/profil" class="text-white text-decoration-none">
                                                <li>
                                                    <a class="dropdown-item" href="#"><i class="bi bi-person-lines-fill"></i> Profil </a>
                                                </li></router-link> -->
                                            <li><hr class="dropdown-divider" /></li>
                                            <li>
                                                <a class="dropdown-item cursor-pointer" @click="deconnexion()"> <i class="bi bi-power"></i> Déconnexion </a>
                                                <form id="logout-form" action="#" method="POST" class="d-none">@csrf</form>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div class="content p-4">
                    <div class="bg-white p-4">
                        <!-- <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="{{ route('publication.list') }}">Publications</a></li>
                            <li class="breadcrumb-item active">Affichage d'une annonce</li>
                        </ol>
                    </nav>

                    <h1 class="h3 fw-bold pb-2 mb-3 border-bottom">Titre de la page</h1>

                    <div class="mt-3">
                      
                    </div> -->
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import axiosInstance from "../api/axios";
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const toggle = function () {
  let wrapper_page = document.getElementById("wrapper-page");
  wrapper_page.classList.toggle("toggled");
};

const userData = JSON.parse(sessionStorage.getItem("infoUser")) || {};
const info = ref(userData);

const deconnexion = function () {
  sessionStorage.removeItem("authToken");
  router.push("/connexion");
};
</script>
<style scoped>
body {
    background-color: #e9edf6;
}
a {
    text-decoration: none;
}
.cursor-pointer {
    cursor: pointer;
}
.form-label {
    font-weight: 700;
}
#wrapper-page.toggled #wrapper-content,
#wrapper-page.toggled .sidebar-vertical {
    margin-left: 0;
}
.sidebar-vertical {
    max-width: 270px;
    margin-left: -17rem;
    transition: margin 0.25s ease-out;
}

.sidebar-heading {
    color: #8eb9f8 !important;
    letter-spacing: 0.08rem;
}

.btn-toggle,
.li-sidebar a {
    display: inline-flex;
    font-size: 1.1rem;
    align-items: center;
    padding: 0.4rem 1rem;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    border: 0;
}
.btn-toggle span,
.li-sidebar span {
    margin-left: 0.4rem;
    transition-duration: 0.3s;
}
.btn-toggle:hover,
.btn-toggle:focus,
.li-sidebar:hover {
    color: #fff;
    background-color: #044cd3dc;
}
.btn-toggle:hover span,
.li-sidebar:hover span {
    margin-left: 1rem;
}
.btn-toggle::after {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%28225,225,225%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
    position: absolute;
    right: 0;
    padding-right: 2rem;
}
.btn-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    color: #fff;
    display: inline-flex;
    padding: 0.1875rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 1.25rem;
    text-decoration: none;
}

.sidebar-vertical::-webkit-scrollbar {
    width: 18px;
}
.sidebar-vertical::-webkit-scrollbar-thumb {
    background-color: #01af27;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}
.sidebar-vertical::-webkit-scrollbar-thumb:hover {
    background-color: #01af27;
}

.sidebar-ul {
    height: 80%;
}
.sidebar-ul::-webkit-scrollbar {
    width: 15px;
}
.sidebar-ul::-webkit-scrollbar-thumb {
    background-color: #01af27;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}
.sidebar-ul::-webkit-scrollbar-thumb:hover {
    background-color: #01af27;
}

#wrapper-content {
    margin-left: 270px;
    min-height: 100vh;
    min-width: 100vw;
    transition: margin 0.25s ease-out;
}
.topnav {
    flex-direction: row !important;
}
.topnav .nav-link {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

#tableData {
    width: 100% !important;
}

/*** responsive ***/
@media (max-width: 767.98px) {
    #wrapper-page.toggled #wrapper-content {
        margin-left: 270px;
    }
    #wrapper-content {
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    #wrapper-content {
        min-width: 0;
        width: 100%;
    }
    .sidebar-vertical {
        margin-left: 0;
    }

    #wrapper-page.toggled .sidebar-vertical {
        margin-left: -17rem;
    }
}
</style>
