<template>
  <div class="min-vh-100 d-flex align-items-center position-relative justify-content-center background">
    <div class="backdrop-blur-sm bg-white shadow-lg p-4 rounded box-border connexion container-md">
      <form @submit.prevent="login">
        <h3 class="text-center mb-4">Connexion</h3>
        <div class="mb-4">
          <label for="email" class="form-label">E-mail<span class="text-primary">*</span></label>
          <input type="email" class="form-control" id="fullemail" name="email" v-model="email" placeholder="dodo@gmail.com" required />
          <div v-if="formErrors.email" class="text-danger">{{ formErrors.email }}</div>
        </div>
        <div class="mb-4">
          <label for="password">Mot de passe<span class="text-primary">*</span></label>
          <input type="password" class="form-control" id="password" required v-model="password" placeholder="8 caractères minimum" />
          <div v-if="formErrors.password" class="text-danger">{{ formErrors.password }}</div>
        </div>
        <div class="d-md-flex justify-content-between align-items-center">
          <button type="submit" class="btn d-flex justify-content-center align-items-center mb-4" :disabled="isLoading" style="background-color: blue;">
            <span v-if="isLoading">Chargement...</span>
            <span v-else class="text-white">Se connecter</span>
          </button>
          <div class="mt-2 fs-6">
            <router-link to="/mot_passe" style="text-decoration:none; color: blue">Mot de passe oublié ?? </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
      formErrors: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    showToastWarning(message) {
      toast.warning(message, {
        autoClose: 3000,
      });
    },
    showToastSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showToatInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
    login() {
      this.isLoading = true; // Activer le chargement

      this.formErrors.email = "";
      this.formErrors.password = "";
      // Étape 1 : Faites une requête pour obtenir le jeton CSRF en initialisant la protection CSRF.
      axiosInstance.get('/sanctum/csrf-cookie')
        .then(response => {
          // Étape 2 : Le cookie XSRF-TOKEN a été défini avec le jeton CSRF actuel.

          // Exemple d'une requête POST avec le jeton CSRF inclus automatiquement.
          axiosInstance.post('api/login', {
            email: this.email,
            password: this.password
          })
            .then(response => {
              console.log('Réponse de la requête POST :', response.data);

              if (response.data.success) {
                this.showToastSuccess("Connexion reussie !!!");

                const authToken = response.data.token_Auth;
                sessionStorage.setItem('authToken', authToken);

                setTimeout(() => {
                  this.$router.push({ name: 'dashboard-ajout-note' });
                }, 3000);
              } else if(response.data.msg) {
                 const errors = response.data.msg;
                for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    this.formErrors[key] = errors[key][0]; // Afficher la première erreur pour chaque champ
                }
                }
                
              } else if (response.data.msg) {
                this.showToastInfo(response.data.msg);
              }
            })
            
            .catch(error => {
              console.error('Erreur lors de la requête POST :', error);
              this.showToastWarning("Erreur lors de la connexion !!!");
            })
            .finally(() => {
              this.isLoading = false; // Désactiver le chargement
            });
        })
        .catch(error => {
          console.error("Erreur lors de l'initialisation de la protection CSRF :", error);
          this.isLoading = false; // Désactiver le chargement en cas d'erreur
        });
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #f6f9ff;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}

input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
}

.shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.connexion {
  width: 100%;
  max-width: 400px; /* ajustez la largeur maximale selon vos besoins */
  margin: auto; /* centre le formulaire horizontalement */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.svg {
  margin-bottom: 350px;
  width: 15%;
  height: 15%;
}
</style>
