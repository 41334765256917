<template>
    <main id="main" class="main body">
           <div class="pagetitle titre">
  <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
    <ol class="breadcrumb m-3">
      
      <li class="breadcrumb-item active" aria-current="page">Communautaires</li>
      <li class="breadcrumb-item active" aria-current="page"><a href="">Liste Communautaire</a> </li>
    </ol>
  </nav>
            <h1>Liste communautaire</h1>
            <hr class="border-bottom" />
        </div>
        <section class="section">
            <div class="card">
                <div class="card-body table-responsive">
                  <!-- Affiche le loader si loading est vrai -->
          <div v-if="loading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Chargement en cours...</span>
            </div>
          </div>

                    <table  v-else  class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Communautaires</th>
                                <th scope="col">Civilités</th>
                                <th scope="col">Télephones</th>
                                <!-- <th v-for="header in tableHeaders" :key="header">{{  header }}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(communautaire, index) in communautaires" :key="index" >
                                <th scope="row">{{ index + 1  }}</th>
                                <td>{{ communautaire.identite }}</td>
                                <td>{{ communautaire.civilite }}</td>
                                 <td>{{ communautaire.telephone }}</td>
                                  
                                <!-- Assurez-vous que le nom du communautaire est correct ici -->
                                <td v-for="header in reseaux" :key="header">
                                    <!-- <router-link :to="'/dashboard/contenu' + '/' + communautaire.id + '/' + header.id">
                                        <a href="" class="text-primary" style="color: white">
                                            <i style="height: 10px; width: 10px" class="bi bi-plus-circle"></i>
                                        </a>
                                    </router-link> -->
                                </td>
                                <td>
                                    <button @click="openEditModal(communautaire)"  class="m-1 btn btn-primary"  data-bs-toggle="modal" data-bs-target="#edit" style="color: white" >
                                    edit
                                </button>
                                </td>
                                <td>
                                    <button @click="deleteCommunautaire(communautaire.id)" class="btn btn-danger">Sup</button>
                                </td>
                                <!-- <td>
              <router-link :to="'/dashboard/detail' + '/' + communautaire. communautaire_id " class="d-block table-info">
              <a href="">
                  <i class="bi bi-three-dots-vertical"></i>
              </a>
                
              </router-link>
            </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <!-- Modal pour la modification -->
                        <div class="modal fade" id="edit" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
                            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title text-center" id="editCommunauteLabel">Modifier un Communautaire</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                        <!-- Ajoutez ici le contenu du formulaire pour la modification -->
                                        <form @submit.prevent="modifierCommunautaire()">
                                                <div class="row">
                                                <div class="col-md-6 mb-3">
                                                <label for="Nom" class="form-label">Nom</label>
                                                <input type="text" id="Nom" v-model="nom"  class="form-control"
                                                    placeholder="">
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="Prenom" class="form-label">Prenom</label>
                                                <input type="text" id="Prenom" v-model="prenom"  class="form-control"
                                                    placeholder="">
                                            </div>
                                        </div>
                                
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="email" class="form-label">E-mail</label>
                                                <input type="email" id="email" v-model="email" class="form-control"  placeholder="">
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="civilité" class="form-label">Civilité</label>
                                                <select id="civilité" v-model="civilite" class="form-select">
                                                    <option value="Monsieur">Monsieur</option>
                                                    <option value="Madame">Madame</option>
                                                    <option value="Mademoiselle">Mademoiselle</option>
                                                </select>
                                            </div>
                                        </div>
                                
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="date" class="form-label">Date Naissance</label>
                                                <input type="date" id="date" v-model="date_naissance" class="form-control" 
                                                    placeholder=" " >
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="domaine_activite" class="form-label">Domaine Activité</label>
                                                <input type="text" id="fullName" v-model="domaine_activite" class="form-control" 
                                                    placeholder="">
                                            </div>
                                        </div>
                                
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="profession" class="form-label">Profession</label>
                                                <input type="text" id="profession" v-model="profession" class="form-control"
                                                    placeholder="">
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="titre" class="form-label">Titre</label>
                                                <input type="text" id="titre" v-model="titre" class="form-control" placeholder="">
                                            </div>
                                        </div>
                                
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="matrimoniale" class="form-label">Matrimoniale</label>
                                                    <select id="matrimoniale" v-model="matrimoniale" class="form-select">
                                                        <option value="Celibataire">Célibataire</option>
                                                        <option value="Marie">Marie (é)</option>
                                                        <option value="Veuf">Veuf</option>
                                                        <option value="Concubinage">Concubinage</option>
                                                            <option value="Fiance">Fiancé</option>
                                                            <option value="Couple">Couple</option>
                                                    </select>
                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="adresse" class="form-label">Adresse</label>
                                                <input type="text" id="adresse" v-model="adresse" class="form-control"
                                                    placeholder="">
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                                                <input type="number" id="phoneNumber" v-model="telephone"  class="form-control"
                                                    placeholder="">
                                            </div>
                                            <div class=" col-md-6 justify-content-center  mt-4">
                                            <button class="btn " style="background:rgba(9, 6, 230, 0.945); color:white;" type="submit">Modifier</button>
                                        </div>
                                        </div>
         
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    data() {
        return {
            reseaux: [],
            tableHeaders: [],
            communautaires: [],
             loading: true,

        nom: '',
      prenom: '',
      email: '',
      civilite: '',
      date_naissance: '',
      domaine_activite: '',
      profession: '',
      titre: '',
      matrimoniale: '',
      adresse: '',
      telephone: '',
      communautaireId: null,
        };
    },
    methods: {

    
        
        Reseaux() {
            axiosInstance
                .get("api/reseaux")
                .then((response) => {
                    this.reseaux = response.data.liste;
                    const reseauxNoms = this.reseaux.map((item) => item.libelle);
                    this.tableHeaders = [...reseauxNoms];
                    if (response.data && response.data.success) {
                        // Récupérez les clés de l'objet JSON pour les utiliser comme en-têtes de tableau
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des réseaux sociaux : ", error);
                });
        },
        Communautaires() {
            axiosInstance
                .get("api/communautaires") // Assurez-vous d'utiliser la bonne URL de votre API pour les communautaires
                .then((response) => {
                    this.communautaires = response.data.liste; // Assurez-vous que les données sont correctement récupérées
                    if (response.data && response.data.success) {
                        // Effectuez d'autres actions si nécessaire
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des communautaires : ", error);
                });
        },

        openEditModal(communautaire) {
    console.log(communautaire);
    this.communautaireId = communautaire.id;
   
    this.nom = communautaire.nom;
    this.prenom = communautaire.prenom;
    this.email = communautaire.email;
    this.civilite = communautaire.civilite;
    this.date_naissance = communautaire.date_naissance;
    this.domaine_activite = communautaire.domaine_activite;
    this.profession = communautaire.profession;
    this.titre = communautaire.titre;
    this.matrimoniale = communautaire.matrimoniale;
    this.adresse = communautaire.adresse;
    this.telephone = communautaire.telephone;
},
modifierCommunautaire() {
      const data = {
            nom:this.nom,
            prenom:this.prenom,
            email:this.email,
            civilite:this.civilite,
            date_naissance:this.date_naissance,
            domaine_activite:this.domaine_activite,
            profession:this.profession,
            matrimoniale:this.matrimoniale,
            titre:this.titre,
            adresse:this.adresse,
            telephone:this.telephone,
      };

      axiosInstance
        .post(`api/communautaires/${this.communautaireId}`, data)
        .then(response => {
          if (response.data && response.data.success) {
            this.showToastSuccess('Communautaire modifié avec succès');
            // Effectuez d'autres actions si nécessaire
          } else {
            this.showToastWarning('Échec de la modification du communautaire');
          }
        })
        .catch((error) => {
          // Gérer les erreurs éventuelles
          this.showToastWarning("Echec de mis à jour")
          console.error(error);
        });
    },

    deleteCommunautaire(communautaireId) {
        if (confirm('Êtes-vous sûr de vouloir supprimer ce communautaire ?')) {
            axiosInstance
                .delete(`api/communautaires/${communautaireId}`)
                .then(response => {
                    this.showToastSuccess(response.data.msg);
                    // Update the list by removing the deleted communautaire
                    this.communautaires = this.communautaires.filter(communautaire => communautaire.id !== communautaireId);
                    if (response.data && response.data.success) {
                        console.log("Communautaire supprimé avec succès :", response.data);
                    } else {
                        console.log(response.data.msg);
                    }
                })
                .catch(error => {
                    // Handle potential errors
                    this.showToastWarning("Échec de suppression du communautaire");
                    console.error(error);
                });
        }
    },

    showToastWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },

    
    },
    mounted() {
        
        this.Reseaux();
        this.Communautaires();
        this.loading = false; 
    },
 };
</script>

<style scoped>
body {
    background-color: #f6f9ff;
    font-size: 17px;
}

a {
    color: #034101;
    text-decoration: none;
}

a:hover {
    color: #010113;
    text-decoration: none;
}

#main {
    margin-top: 5px;
    padding: 20px 30px;
}

@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}

.pagetitle {
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    padding-bottom: 1px;
}

.pagetitle h1 {
    font-size: 24px;
    font-weight: 600;
    color: #044cd3dc;
}

.pagetitle a {
    color: #044cd3dc;
}

.card {
    border: none;
}

.card-title {
    font-weight: bold;
}

.card-title h5 {
    font-size: 20px;
    color: #000918;
}

.card-body {
    padding: 0 20px 20px 20px;
}

.obligatoire {
    color: #034101;
}
</style>