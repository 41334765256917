<template>
    <main id="main" class="main body">
        <div class="pagetitle">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg + xml, %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='%236c757d'/%3E%3C/svg%3E&#34;)" aria-label="breadcrumb">
                <ol class="breadcrumb m-3">
                    <li class="breadcrumb-item">Notes</li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <router-link to="/dashboard/ajout_note" style="text-decoration: none">Ajout note</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Contenu</li>
                </ol>
            </nav>
            <h3 class="fs-4 text-dark">{{ nomCommunautaire }}</h3>
            <hr class="border-bottom" />
            <h3 class="fs-4 text-primary">{{ nomReseaux }}</h3>
            <p>Cocher les réseaux pour le communautaire s'il y a eu d'action</p>
            <div class="row">
                <form @submit.prevent="noter()" >
                    <div class="form-group">
                         <div class="mb-2"  >
                        <label for="date_note" class="pe-2" > <strong>Date</strong></label>
                        <input type="date" v-model="formData.date_note" :max="getCurrentDate()"  >
                    </div>  
                        <div class="form-check form-check-inline">
                            <input class="form-check-input border-black" v-model="like" type="checkbox" id="inlineCheckbox1" value="2" />
                            <label class="form-check-label text-primary" for="inlineCheckbox1"><strong>Like</strong></label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input border-black" v-model="commentaire" type="checkbox" id="inlineCheckbox2" value="3" />
                            <label class="form-check-label text-info-emphasis" for="inlineCheckbox2"><strong>Commentaire</strong></label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input border-black" v-model="partage" type="checkbox" id="inlineCheckbox3" value="5" />
                            <label for="inlineCheckbox3" class="form-label text-danger-emphasis"><strong>Partage</strong></label>
                        </div>
                        

                        <button v-if="loader" class="btn" style="background-color: #019d0b; color: white" type="button">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            chargement...
                        </button>
                        <!-- <router-link to="/dashboard/ajout_note" style="text-decoration: none; color: white">Valider</router-link> -->
                        <button v-else type="submit" class="btn d-flex justify-content-center align-items-center mb-4" style="background-color: blue">
                            <span class="text-white">Valider</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import "vue3-toastify/dist/index.css";

export default {
    data() {
        return {
            
            reseaux: "",
            nomReseaux: "",
            nomCommunautaire: "",
            communautaires: "",
            loader: false,
            like: false,
            commentaire: false,
            partage: false,
            formData: {
                communautaires: "",
                reseaux: "",
                like: Number,
                commentaire: Number,
                partage: Number,
                date_note: "",
            },
             date_note: "",
             endpoint: "",
             nomReseaux:"",
        };
    },
    methods: {

        getCurrentDate() {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; // Les mois commencent à 0
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${yyyy}-${mm}-${dd}`;
    },
        noter() {
         if (this.formData.reseaux == 1) {
                this.endpoint = "/api/note_facebook";
            } else if (this.formData.reseaux == 2) {
                this.endpoint = "/api/note_instagram";
            } else if (this.formData.reseaux == 3) {
                this.endpoint = "/api/note_twitter";
            } else if (this.formData.reseaux == 4) {
                this.endpoint = "/api/note_tikTok";
            } else if (this.formData.reseaux == 5) {
                this.endpoint = "/api/note_linkedin";
            }

            if (this.like == true) {
                this.formData.like = 2;
            } else {
                this.formData.like = 0;
            }

            if (this.commentaire == true) {
                this.formData.commentaire = 3;
            } else {
                this.formData.commentaire = 0;
            }

            if (this.partage == true) {
                this.formData.partage = 5;
            } else {
                this.formData.partage = 0;
            }

            axiosInstance
                .post(this.endpoint, this.formData)
                .then((response) => {
                    let statut = response.data.status;

                    if (statut == "422") {
                        const backendErrors = response.data.msg;
                        for (let el in backendErrors) {
                            this.showToastInfo(`${backendErrors[el][0]}`);
                        }
                        this.isLoading = false;
                    } else {
                        this.showToastSuccess(`${response.data.msg}`);
                        this.isLoading = false;

                        setTimeout(() => {
                            this.$router.push("/dashboard/ajout_note");
                        }, 3000);
                    }
                    this.resetFormFields();
                })
                .catch((error) => {
                    console.log(error);
                });
            console.log(this.formData);
        },
        // getReseau() {
        //     axiosInstance
        //         .get("api/reseaux")
        //         .then((response) => {
        //             this.reseaux = response.data.liste;
        //             this.nomReseaux = this.reseaux.filter((item) => item.id == this.formData.reseaux).libelle;
        //             console.log(this.nomReseaux);
        //         })
        //         .catch((error) => {
        //             console.error("Erreur lors de la récupération des réseaux sociaux : ", error);
        //         });
        // },
        getCommunautaires() {
            axiosInstance
                .get("api/communautaires") // Assurez-vous d'utiliser la bonne URL de votre API pour les communautaires
                .then((response) => {
                    this.communautaires = response.data.liste; // Assurez-vous que les données sont correctement récupérées
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des communautaires : ", error);
                });
        },

        resetFormFields() {
    // Réinitialiser les champs du formulaire à leur état initial ou vide
    this.formData.date_note = "";
    this.like = false;
    this.commentaire = false;
    this.partage = false;
  },
        showToastWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },
    },
    mounted() {
        const route = useRoute();
        this.formData.communautaires = route.params.communautaireId;
        this.formData.reseaux = route.params.reseauxId;

            if (this.formData.reseaux == 1) {
               this.nomReseaux ="Facebook" ;
            } else if (this.formData.reseaux == 2) {
                this.nomReseaux = "Instagram";
            } else if (this.formData.reseaux == 3) {
                this.nomReseaux = "Twitter";
            } else if (this.formData.reseaux == 4) {
                 this.nomReseaux = "TickTok";
            } else if (this.formData.reseaux == 5) {
              this.nomReseaux = "LinkedIn";
            }
     
        this.getCommunautaires();

         
    },
};
</script>

<style scoped></style>
