import { VueRouter, createRouter, beforeEach, createWebHistory } from "vue-router";

import connexionView from "../views/connexionView.vue";

import mot_passeView from "../views/mot_passeView.vue";
import reinitialisationView from "../views/reinitialisationView.vue";
import Layout from "../layouts/dashboard.vue";
import profil from "../views/profilView.vue";
import contenu from "../views/contenuView.vue";
import ajout_note from "../views/ajout_noteView.vue";
import insta from "../views/instaView.vue";
import ticktok from "../views/ticktokView.vue";
import linkedin from "../views/linkedinView.vue";
import twitter from "../views/twitterView.vue";
import bilan from "../views/bilanView.vue";
import bilan_responsable from "../views/bilan_responsableView.vue";
import bilan_communautaire from "../views/bilan_communautaireView.vue";
import detail from "../views/detailView.vue";
import liste_communaute from "../views/liste_communauteView.vue";
import ajout_communaute from "../views/ajout_communauteView.vue";

function isAuthenticated() {
    var isAuthenticated = false;
   
    if (sessionStorage.getItem("authToken") !== null) {
        isAuthenticated = true;
    } else isAuthenticated = false;
    console.log(isAuthenticated) 
    return isAuthenticated;
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "connexion",
            component: connexionView,
            meta: { title: "connexion", requiredAuth: false },
        },

        {
            path: "/mot_passe",
            name: "mot_passe",
            component: mot_passeView,
            meta: { title: "connexion", requiredAuth: false },
        },

        {
            path: "/password/reset/:email/:token",
            name: "reinitialisation",
            component: reinitialisationView,
            meta: { title: "connexion", requiredAuth: false },
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Layout,
            meta: { title: "dashboard", requiredAuth: true },
            children: [
                {
                    path: "profil",
                    name: "profil",
                    component: profil,
                    meta: { title: "profil", requiredAuth: true },
                },
                {
                    path: "ajout_communaute",
                    name: "ajout_communaute",
                    component: ajout_communaute,
                    meta: { title: "ajout_communaute", requiredAuth: true },
                },
                {
                    path: "liste_communaute",
                    name: "liste_communaute",
                    component: liste_communaute,
                    meta: { title: "liste_communaute", requiredAuth: true },
                },


                {
                    path: "ajout_note",
                    name: "dashboard-ajout-note",
                    component: ajout_note,
                    meta: { title: "ajout_note", requiredAuth: true },
                },
                {
                    path: "contenu/:communautaireId/:reseauxId",
                    name: "contenu",
                    component: contenu,
                    meta: { title: "contenu", requiredAuth: true },
                },
                {
                    path: "insta",
                    name: "insta",
                    component: insta,
                    meta: { title: "insta", requiredAuth: true },
                },
                {
                    path: "ticktok",
                    name: "ticktok",
                    component: ticktok,
                    meta: { title: "ticktok", requiredAuth: true },
                },
                {
                    path: "twitter",
                    name: "twitter",
                    component: twitter,
                    meta: { title: "twitter", requiredAuth: true },
                },
                {
                    path: "linkedin",
                    name: "linkedin",
                    component: linkedin,
                    meta: { title: "linkedin", requiredAuth: true },
                },
                {
                    path: "bilan",
                    name: "bilan",
                    component: bilan,
                    meta: { title: "bilan", requiredAuth: true },
                },
                {
                    path: "bilan_communautaire",
                    name: "bilan_communautaire",
                    component: bilan_communautaire,
                    meta: { title: "bilan_communautaire", requiredAuth: true },
                },
                {
                    path: "bilan_responsable",
                    name: "bilan_responsable",
                    component: bilan_responsable,
                    meta: { title: "bilan_responsable", requiredAuth: true },
                },
                {
                    path: "detail/:communautaireId/",
                    name: "detail",
                    component: detail,
                    meta: { title: "detail", requiredAuth: true },
                },
            ],
        },
        {
            path: "/about",
            name: "about",
            component: () => import("../views/AboutView.vue"),
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (isAuthenticated() && !to.meta.requiredAuth) {
        return next({ name: "dashboard" });
    } else if (!isAuthenticated() && to.meta.requiredAuth) {
         return next({ path: "/" });
    }
     return next();
 });
export default router;
