<template>

    <div class="container">
      <div class="row">
         <div class="pagetitle titre">
  <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
    <ol class="breadcrumb m-3">
      
      <li class="breadcrumb-item active" aria-current="page">Communautaires</li>
      <li class="breadcrumb-item active" aria-current="page"><a href="">Ajouter Communautaire</a> </li>
    </ol>
  </nav>
  
                    <h1 class="m-3">Ajout Communautaire</h1>
                      <hr class="border-bottom">
                    
                    <div class="row mt-2">
          <div class="user   ">
               <div class="col-md-12 m-2">
               
                
  
                  
              </div><br>
              <div class="col-md-12 mt-1">
                   <form @submit.prevent="communaute">
                   <div class="row">
                   <div class="col-md-6 mb-3">
                  <label for="fullPrenom" class="form-label">Nom</label>
                  <input type="text" id="fullIdentite" v-model="nom"  class="form-control"
                      placeholder="" required> 
              </div>
              <div class="col-md-6 mb-3">
                  <label for="fullPrenom" class="form-label">Prenom</label>
                  <input type="text" id="fullIdentite" v-model="prenom"  class="form-control"
                      placeholder="" required>
              </div>
          </div>
  
          <div class="row">
              <div class="col-md-6 mb-3">
                  <label for="email" class="form-label">E-mail</label>
                  <input type="email" id="email" v-model="email" class="form-control" 
                   placeholder="">
              </div>
              <div class="col-md-6 mb-3">
                  <label for="civilité" class="form-label">Civilité</label>
                  <select id="civilité" v-model="civilite" class="form-select" required>
                      <option value="Monsieur">Monsieur</option>
                      <option value="Madame">Madame</option>
                      <option value="Mademoiselle">Mademoiselle</option>
                  </select>
              </div>
          </div>
  
          <div class="row">
              <div class="col-md-6 mb-3">
                  <label for="date" class="form-label">Date Naissance</label>
                  <input type="date" id="date" v-model="date_naissance" class="form-control" 
                      placeholder=" " :max="getCurrentDate()" >
              </div>
              <div class="col-md-6 mb-3">
                  <label for="domaine_activite" class="form-label">Domaine Activité</label>
                  <input type="text" id="fullName" v-model="domaine_activite" class="form-control" 
                      placeholder="" >
              </div>
          </div>
  
          <div class="row">
              <div class="col-md-6 mb-3">
                  <label for="profession" class="form-label">Profession</label>
                  <input type="text" id="profession" v-model="profession" class="form-control"
                      placeholder="" >
              </div>
              <div class="col-md-6 mb-3">
                  <label for="titre" class="form-label">Titre</label>
                  <input type="text" id="titre" v-model="titre" class="form-control" 
                   placeholder="">
              </div>
          </div>
  
          <div class="row">
              <div class="col-md-6 mb-3">
               <label for="matrimoniale" class="form-label">Matrimoniale</label>
                  <select id="matrimoniale" v-model="matrimoniale" class="form-select">
                      <option value="Celibataire">Célibataire</option>
                      <option value="Marie">Marie (é)</option>
                      <option value="Veuf">Veuf</option>
                       <option value="Concubinage">Concubinage</option>
                        <option value="Fiance">Fiancé</option>
                         <option value="Couple">Couple</option>
                  </select>
              </div>
              <div class="col-md-6 mb-1">
                  <label for="adresse" class="form-label">Adresse</label>
                  <input type="text" id="adresse" v-model="adresse" class="form-control"
                      placeholder="" >
              </div>
          </div>
        
          <div class="row">
            <div class="col-md-6 mb-3">
                  <label for="phoneNumber" class="form-label">Numéro de téléphone</label>
                  <input type="number" id="phoneNumber" v-model="telephone"  class="form-control"
                      placeholder="" required>
              </div>
               <div class="col-md-6 mb-3">
            <label for="type" class="form-label">Type</label>
                  <select id="type" v-model="type" class="form-select">
                      <option value="Communautaire">Communautaire</option>
                      <option value="Responsable">Responsable </option>
                  
                      
                  </select>
                   </div>
          </div>
            <div class=" col-md-6 justify-content-center  mt-4">
              <button class="btn " style="background:rgba(9, 6, 230, 0.945); color:white;" type="submit">Ajouter</button>
          </div>
          <div v-if="errors.length > 0" class="alert alert-danger mt-2">
      <p>Veuillez corriger les erreurs suivantes :</p>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>
      </form>
  </div>
  
             
      </div>
    </div>
  
              </div>
      </div>
      
    
       </div>
     
  </template>
  
  <script>
  import axiosInstance from "../api/axios";
  import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
  export default {
    data() {
      return {
        nom:"",
        prenom:"",
        email:"",
        civilite:"",
        date_naissance:"",
        domaine_activite:"",
        profession:"",
        matrimoniale:"",
        titre:"",
        adresse:"",
        type:"",
        telephone:"",
        errors: [],
        

      };
    },
    methods: {

      getCurrentDate() {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; // Les mois commencent à 0
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${yyyy}-${mm}-${dd}`;
    },

     communaute(){

        const userData={
            nom:this.nom,
            prenom:this.prenom,
            email:this.email,
            civilite:this.civilite,
            date_naissance:this.date_naissance,
            domaine_activite:this.domaine_activite,
            profession:this.profession,
            matrimoniale:this.matrimoniale,
            titre:this.titre,
            type:this.type,
            adresse:this.adresse,
            telephone:this.telephone,
         
        }

        axiosInstance
        .post("api/communautaires", userData)
        .then((response) => {
          
          this.showToastSuccess(response.data.msg);
          console.log(response.data && response.data.success)
          if (response.data && response.data.success) {
            console.log("communautaire ajouté :", response.data);
            this.resetFields();
          } else {
            console.log(response.data.msg);
            this.errors = [];
            // Gestion des erreurs retournées par le backend
            this.errors = Object.values(response.data.errors).flat();
            this.showToastWarning("Échec d'ajout");
          }
          
        })
        .catch((error) => {
          // Gérer les erreurs éventuelles
          this.showToastWarning("Echec d'ajout")
          console.error(error);
        });
     },

     resetFields() {
      // Réinitialiser les valeurs des champs à vide
      this.nom = "";
      this.prenom = "";
      this.email = "";
      this.civilite = "";
      this.date_naissance = "";
      this.domaine_activite = "";
      this.profession = "";
      this.matrimoniale = "";
      this.titre = "";
      this.adresse = "";
      this.telephone = "";
      this.type="";

      
    },

     showToastWarning(message) {
            toast.warning(message, {
                autoClose: 3000,
            });
        },
        showToastSuccess(message) {
            toast.success(message, {
                autoClose: 3000,
            });
        },
        showToastInfo(message) {
            toast.info(message, {
                autoClose: 3000,
            });
        },

    },
  };
  </script>
  
  
  <style scoped>
  
  .pagetitle {
      margin-top: 15px;
      margin-bottom: 10px;
      background-color: white;
      padding: 10px;
      padding-bottom: 1px;
    }
    
    .pagetitle h1 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 600;
      color: blue;
  }
  .pagetitle a {
      color: blue;
      text-decoration: none;
  }
  
  .liste{
    margin-top: 20px;
    width:103%;
    background-color: white;
    margin-right: 50px;
  
     
  }
  .para0{
   font-weight: bold;
   margin-bottom: 1px;
  }
  
      
  .user{
  
    border: none;
  
  }
  
  .bouton{
      color:blue;
      border-color:blue;
  }
  .bouton:hover{
  background-color:blue;
  color:white;
  }
  input{
      background-color:#f6f9ff;
  }
  
  select{
        background-color:#f6f9ff;
  }
  </style>
  