<template>
  <main id="main" class="main body">
        <div class="pagetitle">
               <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                    <ol class="breadcrumb m-3">
                        <li class="breadcrumb-item">Notes</li>
                        <li class="breadcrumb-item active" aria-current="page"><router-link to="/dashboard/ajout_note" style="text-decoration:none">Ajout note</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Contenu</li>
    
                    </ol>
                </nav>
            <h3>Nom communautaire</h3>
            <hr class="border-bottom" />
            <h3 class="fs-4 text-primary">Nom Réseau</h3>
            <p>Cocher les reseaux pour le communautaire s'il a eu d'action</p>
            <div class="row" >
                <form action="">
                    <div class="">
                        <div class="form-check form-check-inline ">
                        <input class="form-check-input border-black" v-model="like" type="checkbox" id="inlineCheckbox1" value="like" >
                        <label class="form-check-label text-primary" for="inlineCheckbox1"><strong>Like</strong></label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input border-black" v-model="commentaire"  type="checkbox" id="inlineCheckbox2" value="commentaire">
                        <label class="form-check-label text-info-emphasis"  style=" font-" for="inlineCheckbox2"><strong>Commentaire</strong></label>
                        </div>
                        <div class="form-check form-check-inline">
                        <input class="form-check-input border-black" v-model="partage"  type="checkbox" id="inlineCheckbox2" value="partage">
                        <label for="partage" class="form-label text-danger-emphasis"><strong>Partage</strong></label>
                         </div>
               
                        <button type="submit" class="btn d-flex justify-content-center align-items-center mb-4"  style="background-color: blue; ">
                 
                    <span ><router-link to="/dashboard/ajout_note" style="text-decoration:none; color: white">Valider</router-link></span>
                </button>
                    </div>
                </form>
            </div>
        </div>
    </main>
</template>
<script>
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  data() {
    return {
      like: false,
      commentaire: false,
      partage: false,
      loader: false, // Ajout de la propriété "loader" pour indiquer le chargement
      
    };
  },
  methods: {
    showToatWarning(message) {
      toast.warning(message, {
        autoClose: 3000,
      });
    },
    showToatSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showToatInfo(message) {
      toast.info(message, {
        autoClose: 3000,
      });
    },

    sendValuesToBackend() {
      this.loader = true; // Activer le chargement pendant la requête

      const usersData = {
        like: this.like ? 2 : 0,
        commentaire: this.commentaire ? 3 : 0,
        partage: this.partage ? 4 : 0,
        
      };

      axiosInstance
        .post("api/note_instagram", usersData)
        .then((response) => {
          this.loader = false; // Désactiver le chargement après la réponse
          this.showToatSuccess(response.data.msg);

          if (response.data && response.data.success) {
            console.log(response.data);
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          this.loader = false; // Désactiver le chargement en cas d'erreur
          console.error(error);
          this.showToatWarning("Echec");
        });
    },
  },

  
};
</script>