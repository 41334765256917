<template>
  <main id="main" class="main body">
    <div class="pagetitle">
      <nav
        style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"
        aria-label="breadcrumb"
      >
        <ol class="breadcrumb m-3">
          <li class="breadcrumb-item">Bilans</li>
          <li class="breadcrumb-item active" aria-current="page">
            <router-link to="/dashboard/ajout_note" style="text-decoration:none"
              >Bilan Périodique</router-link
            >
          </li>
        </ol>
      </nav>

      <h3>Bilan Périodique</h3>
      <hr class="border-bottom" />
      <p>Renseigner une date ici<i class="bi bi-emoji-sunglasses-fill text-warning "></i></p>
      <div class="row">
        <form @submit.prevent="soumettre" class="justify-content-center">
          <div class="row">
            <div class="col-3">
              <label for="dateDebut" class="pe-2"><strong>Date de Début</strong></label>
              <input type="date" v-model="dateDebut" id="dateDebut" :max="getCurrentDate()" />
            </div>
            <div class="col-3">
              <label for="dateFin" class="pe-2"><strong>Date de Fin</strong></label>
              <input type="date" v-model="dateFin" id="dateFin" :max="getCurrentDate()" />
            </div>
            <div class="col-3 mt-3 d-flex">
              <button
                type="submit"
                class="btn d-flex justify-content-center align-items-center mb-4"
                style="background-color: blue; color: white"
              >
                Afficher
              </button>
            </div>
            <div v-if="isTableFilled" class=" col-3 mt-3">
              <button
                @click="telechargerPDF"
                class="btn  mb-3 "
                style="background-color: blue; color: white"
              >
                Télécharger en PDF
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <p class="text-center text-white" style="background-color: blue;">
              <strong>{{ periode }}</strong>
            </p>
          </div>
        </div>

        <div class="row  mb-2">
          <div class="col-6">
            <label for="searchCommunautaires" class="form-label"
              ><strong>Rechercher un communautaire</strong></label
            >
            <input
              type="text"
              v-model="searchTerm"
              id="searchCommunautaires"
              class="form-control"
              placeholder="Entrez le nom du communautaire"
            />
          </div>
        </div>
        <table class="table table-responsive">
          <thead>
            <tr>
              <th class="table-active" scope="col">N°</th>
              <th class="table-active" scope="col">Communautaires</th>
              <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
              <th class="table-active" scope="col">Total Point</th>
              <th class="table-active" scope="col">Tel</th>
              <th class="table-active" scope="col">Détails</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr  v-for="(communautaire, index) in bilanData" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ communautaire.communautaire }}</td>
              <td v-for="point in communautaire.total" :key="point">{{ point }}</td>
              <td>{{ communautaire.total_note_facebook }}</td>
              <td>{{ communautaire.total_note_instagram }}</td>
              <td>{{ communautaire.total_note_twitter }}</td>
              <td>{{ communautaire.total_note_tiktok }}</td>
              <td>{{ communautaire.total_note_linkedin }}</td>
              <td>{{ communautaire.total_note }}</td>
               <td>{{ communautaire.telephone }}</td>
              

              <td>
                <router-link
                  :to="'/dashboard/detail' + '/' + communautaire. communautaire_id "
                  class="d-block table-info"
                >
                  <a href="">
                    <i class="bi bi-three-dots-vertical"></i>
                  </a>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axiosInstance from "../api/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  data() {
    return {
      searchTerm: "",
      reseaux: [],
      tableHeaders: [],
     
      dateDebut: "",
      dateFin: "",
      bilanData: [],
        communautaires: [],
      isTableFilled: false,
      periode: "",
    };
  },
  methods: {
    telechargerPDF() {
      const table = document.querySelector('.table');

      html2canvas(table).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
        pdf.save('donnees_communautaires.pdf');
      });
    },

    getCurrentDate() {
      const today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; // January is 0
      const yyyy = today.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }

      return `${yyyy}-${mm}-${dd}`;
    },

    Reseaux() {
      axiosInstance
        .get("api/reseaux")
        .then((response) => {
          this.reseaux = response.data.liste;
          const reseauxNoms = this.reseaux.map((item) => item.libelle);
          this.tableHeaders = [...reseauxNoms];
          if (response.data && response.data.success) {
            // Récupérez les clés de l'objet JSON pour les utiliser comme en-têtes de tableau
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des réseaux sociaux : ", error);
        });
    },
  

    getBilan(data) {
      axiosInstance
        .post("/api/notes/total", data) 
        .then((response) => {
          this.showToastSuccess("Récupération des données");
          this.bilanData = response.data.total;
          this.periode = `Période sélectionnée: ${this.dateDebut} au ${this.dateFin}`;
          this.isTableFilled = true; // Mettez à jour l'état après le remplissage du tableau
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi des données du bilan : ", error);
          this.showToastInfo("Erreur lors de la récupération des données");
        });
    },

    soumettre() {
      // Si l'utilisateur n'a pas choisi de date de fin, utilisez la date actuelle
      if (!this.dateFin) {
        const today = new Date();
        this.dateFin = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      }

      const formData = {
        dateDebut: this.dateDebut,
        dateFin: this.dateFin,
      };

      this.getBilan(formData);
    },
    showToastWarning(message) {
      toast.warning(message, {
        autoClose: 3000,
      });
    },
    showToastSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showToastInfo(message) {
      toast.info(message, {
        autoClose: 3000,
      });
    },
  },

  mounted() {
    this.Reseaux();
  
  },
   computed: {
    // Utilisez une propriété calculée pour filtrer les communautaires en fonction du terme de recherche
    filteredCommunautaires() {
      if (this.communautaires && this.communautaires.filter) {
        // Utilisez filter ici
        return this.communautaires.filter(communautaire => {
          // Ajoutez votre logique de filtre ici
          // Par exemple, vérifiez si le nom du communautaire inclut le terme de recherche
          return communautaire.communautaire.includes(this.searchTerm);
        });
      } else {
        return []; // ou une autre valeur par défaut
      }
}

  },
};
</script>

<style scoped>
input {
  border-radius: 10px;
  width: 70%;
}
</style>